<template>
    <v-card rounded="lg" class="s-contact" :disabled="formDisabled">
        <v-card-title style="border-bottom: 1px solid #ddd;">
            <h5 class="pb-0"><b>{{ $t('contact') }}</b></h5>
            <v-spacer></v-spacer>
            <v-btn color="primary" dense depressed @click="goToContact" :disabled="!this.superlead.contact_id">{{$t('goToContact')}}</v-btn>
        </v-card-title>
        <v-card-text>
            <FormComponent v-if="form" :form="form" ref="formContactComponent" />
            <div v-else class="d-flex justify-center align-center" style="min-height: 400px">
                <div>
                    <v-form ref="customSearchContact">
                        <Component :is="contactChoiceField.component" :field="contactChoiceField" :fieldName="'choice'" :model="contactModel" />
                        <Component :is="contactSelectorField.component" v-if="contactModel.choice == 'existingContact'" :field="contactSelectorField" :fieldName="'id'" :model="contactModel" />
                        <v-row v-if="contactModel.choice == 'newContact'">
                            <v-col>
                                <Component :is="contactFirstnameField.component" :model="contactModel" :fieldName="'firstname'" :field="contactFirstnameField" />
                            </v-col>
                            <v-col>
                                <Component :is="contactNameField.component" :model="contactModel" :fieldName="'name'" :field="contactNameField" />
                            </v-col>
                            <v-col>
                                <Component :is="contactCompanyField.component" :model="contactModel" :fieldName="'company'" :field="contactCompanyField" />
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="col col-12 pa-0 input-form d-flex align-center">
                        <v-btn dense depressed color="pink" class="white--text" @click="confirmContact" :loading="confirmContactLoader">{{ $t('buttonConfirm') }}</v-btn>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>  
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import VBtnToggle from "@/components/forms/inputs/v-btn-toggle";
import VCustomSelect from "@/components/forms/inputs/v-custom-select";
import VTextField from "@/components/forms/inputs/v-text-field";
import FormComponent from "@/components/forms/FormComponent";
import getForm from "@/mixins/mixins";

export default {
    name: "SContact",
    props: ["superlead", "superleadBase", "formDisabled"],
    components: { VBtnToggle, VCustomSelect, VTextField, FormComponent },
    mixins: [getForm],
    data() {
        return {
            contactChoiceField: {
                "component": "VBtnToggle",
                "title": "Contact_choiceTitle",
                "props": {
                    "items": [
                        { "value": "existingContact", "label": "existingContact" },
                        { "value": "newContact", "label": "newContact" }
                    ],
                    "solo": true, "flat": true, "dense": true, "required": true, 
                },
            },
            contactSelectorField: { 
                "component": "VCustomSelect",
                "title": "findContact",
                "props": { "solo": true, "flat": true, "dense": true, "required": true, "validate-on-blur": true, "searchAjax": true },
                "rules": [{ "fonction": "required", "params": [] }],
                "selectDataUrl": "/entity/" + (this.superlead.entity_id.id ? this.superlead.entity_id.id : this.superlead.entity_id) + "/getContactSelectList?limit=1000",
            },
            contactFirstnameField: {
                "component": "VTextField",
                "title": "contactFirstnameTitle",
                "props": { "dense": true, "solo": true, "flat": true },
                "rules": [{ "fonction": "requiredIfOthersAreEmpty", "params": [["name", "company"], "firstnameOrNameOrCompanyRequired"] }],
            },
            contactNameField: {
                "component": "VTextField",
                "title": "contactNameTitle",
                "props": { "dense": true, "solo": true, "flat": true },
                "rules": [{ "fonction": "requiredIfOthersAreEmpty", "params": [["firstname", "company"], "nameOrFirstnameOrCompanyRequired"] }],
            },
            contactCompanyField: {
                "component": "VTextField",
                "title": "contactCompanyTitle",
                "props": { "dense": true, "solo": true, "flat": true },
                "rules": [{ "fonction": "requiredIfOthersAreEmpty", "params": [["firstname", "name"], "companyOrNameOrFirstnameRequired"] }],
            },
            contactModel: {
                "choice": "existingContact",
                "entity_id": (this.superlead.entity_id.id ? this.superlead.entity_id.id : this.superlead.entity_id),
            },
            form: null,
            confirmContactLoader: false,
        };
    },
    async created() {
        if(this.superlead && this.superlead.contact_id && this.superlead.contact_id.id) {
            await this.getForm("/superlead/getForm?formName=contact&id=" + this.superlead.contact_id.id, false, false, null, null, false);
            this.$set(this.superleadBase, 'contact_id', this._.cloneDeep(this.superlead.contact_id))
        }
    },
    watch: {
        form: {
            handler(val){
                if(val && val.model) {
                    this.$set(this.superlead, 'contact_id', this._.cloneDeep(val.model))
                }
            },
            deep:true
        }
    },
    methods: {
        goToContact() {
            let routeDataLink = this.$router.resolve({ name: 'ContactDetail', params: { contact_id: this.superlead.contact_id.id } });
            window.open(routeDataLink.href, '_blank');
        },
        confirmContact() {
            if(this.$refs.customSearchContact.validate() === true) {
                this.confirmContactLoader = true;
                if(this.contactModel.choice == 'existingContact') {
                    this.getForm("/superlead/getForm?formName=contact&id=" + this.contactModel.id, false, false, null, null, false);
                    this.$set(this.superlead, 'contact_id', this._.cloneDeep({ id: this.contactModel.id }))
                    this.$emit('reloadDemands');
                    this.confirmContactLoader = false;
                } else {
                    delete this.contactModel.id;
                    GenericDataService.postData("/contact/add", this.contactModel).then((response) => {
                        this.getForm("/superlead/getForm?formName=contact&id=" + response.data.data.id, false, false, null, null, false);
                        this.$set(this.superlead, 'contact_id', this._.cloneDeep({ id: response.data.data.id }))
                        this.$emit('reloadDemands');
                        this.confirmContactLoader = false;
                    });
                }
            }   
        }
    },
}
</script>

<style lang="scss">
</style>
